import React, { useCallback } from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import { useBackendApi } from '../../contexts/BackendApiContext';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import ContentArea from '../../molecules/common/ContentArea';
import ContentAreaMainHeading from '../../molecules/common/ContentAreaMainHeading';
import TermsOfService from '../../molecules/common/TermsOfService';

import ButtonPrimary from '../../atoms/ButtonPrimary';

import * as styles from './index.module.css';

const ConfirmTermsOfServiceTemplate = ({ pageContext }) => {
  const { pagePath } = pageContext;

  const { currentTermsOfServiceVersion, renewTermsOfService } = useBackendApi();

  const onConfirmButtonClick = useCallback(() => {
    renewTermsOfService();
  }, [renewTermsOfService]);

  return (
    <PublicRoot>
      <SEO path={pagePath} title="利用規約の確認" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <section>
              <ContainerDefault>
                <ContentArea>
                  <div className={styles['headingWrapper']}>
                    <ContentAreaMainHeading heading="利用規約の確認" />
                  </div>

                  {currentTermsOfServiceVersion ? (
                    <div className={styles['ledeWrapper']}>
                      <p className={styles['lede']}>
                        利用規約が更新されました。
                      </p>
                      <p className={styles['lede']}>
                        利用を継続される場合、以下の利用規約の同意をお願いいたします。
                      </p>
                    </div>
                  ) : (
                    <div className={styles['ledeWrapper']}>
                      <p className={styles['lede']}>
                        TORCHに会員登録いただき、誠にありがとうございます。
                      </p>
                      <p className={styles['lede']}>
                        以下の利用規約に同意の上、会員登録の完了をお願いいたします。
                      </p>
                    </div>
                  )}

                  <div className={styles['termsOfServiceWrapper']}>
                    <TermsOfService />
                  </div>

                  <InlineBlockWrapper
                    className={styles['confirmButtonWrapper']}
                  >
                    <ButtonPrimary onClick={onConfirmButtonClick}>
                      利用規約に同意する
                    </ButtonPrimary>
                  </InlineBlockWrapper>
                </ContentArea>
              </ContainerDefault>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default ConfirmTermsOfServiceTemplate;
