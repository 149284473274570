import React from 'react';

import ConfirmTermsOfServiceTemplate from '../templates/ConfirmTermsOfServiceTemplate';

const ConfirmTermsOfServicePage = () => (
  <ConfirmTermsOfServiceTemplate
    pageContext={{ pagePath: '/confirm-terms-of-service' }}
  />
);

export default ConfirmTermsOfServicePage;
