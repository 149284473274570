import React from 'react';

import * as styles from './index.module.css';

const ContentArea = ({ children }) => {
  return (
    <div className={styles['root']}>
      <div className={styles['inner']}>{children}</div>
    </div>
  );
};

export default ContentArea;
