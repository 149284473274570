import React from 'react';
import { Location } from '@reach/router';

import useInitialSettings from '../../hooks/useInitialSettings';

const PublicRoot = ({ location, children }) => {
  useInitialSettings({ location });

  return <div className="root">{children}</div>;
};

export default ({ children }) => (
  <Location>
    {({ location }) => <PublicRoot location={location}>{children}</PublicRoot>}
  </Location>
);
