import { useEffect } from 'react';
import { navigate } from 'gatsby';

import { useAuth0 } from '../../contexts/Auth0Context';
import { useBackendApi } from '../../contexts/BackendApiContext';
import { EMAIL_SETTINGS_DELIVERY } from '../../contexts/BackendApiContext/EmailSettingsContext';

const useInitialSettings = ({ location }) => {
  const { isAuth0Ready, isAuthenticated } = useAuth0();
  const {
    isBackendApiInitializable,
    isBackendApiReady,
    initBackendApi,

    isUserReady,
    user,
    isRequireTosConfirmation,
    isRequireEmailVerification,
    isRequireUpdateClaims,

    isEmailSettingsReady,
    isRequireCreateEmailSettings,
    createEmailSettings,
  } = useBackendApi();

  useEffect(() => {
    // Wait until auth0 is ready
    if (!isAuth0Ready) {
      return;
    }

    const pathname = location.pathname;

    if (!isAuthenticated) {
      // The following pages are not displayed in the non-authenticated state.
      if (
        pathname.indexOf('/callback') === 0 ||
        pathname.indexOf('/confirm-terms-of-service') === 0
      ) {
        navigate('/');
      }
    } else {
      if (!isBackendApiReady) {
        if (isBackendApiInitializable) {
          initBackendApi();
        }
        return;
      }

      if (!isUserReady || !isEmailSettingsReady) {
        return;
      }

      if (isRequireTosConfirmation) {
        if (pathname.indexOf('/confirm-terms-of-service') === -1) {
          navigate('/confirm-terms-of-service');
        }
        return;
      }

      if (isRequireEmailVerification) {
        if (pathname.indexOf('/email-verification') === -1) {
          navigate('/email-verification');
        }
        return;
      }

      if (!isEmailSettingsReady) {
        return;
      }

      if (isRequireCreateEmailSettings) {
        createEmailSettings({
          variables: {
            email: user.email,
            delivery: EMAIL_SETTINGS_DELIVERY.ALLOW,
          },
        });
        return;
      }

      if (isRequireUpdateClaims) {
        if (pathname.indexOf('/mypage') === -1) {
          navigate('/mypage');
        }
        return;
      }

      if (
        pathname.indexOf('/callback') === 0 ||
        pathname.indexOf('/confirm-terms-of-service') === 0 ||
        pathname.indexOf('/email-verification') === 0
      ) {
        navigate('/');
      }
    }
  }, [
    location,
    isAuth0Ready,
    isAuthenticated,
    isBackendApiInitializable,
    isBackendApiReady,
    initBackendApi,
    isUserReady,
    user,
    isRequireTosConfirmation,
    isRequireEmailVerification,
    isRequireUpdateClaims,
    isEmailSettingsReady,
    isRequireCreateEmailSettings,
    createEmailSettings,
  ]);
};

export default useInitialSettings;
